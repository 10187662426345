<template>
    <div>
        <div class="pb-3 flex relative flex-col flex-nowrap h-full">
            <h2 class="shrink-0 py-3 border-b-2 dark:border-white border-black">Programming</h2>
            <ul class="list-disc grow list-inside mt-1 text-gray-500 ml-2 transition duration-150">
                <li>
                    <span class="text-white"><a href="https://friendsfm.app" target="_blank">FriendsFM</a>
                        <a target="_blank" href="https://github.com/mdean808/friendsfm" title="view the source on github">
                            <svg
                                class="mb-1 ml-1 inline w-6 h-6 text-white hover:text-black"
                                data-donate="true" data-tag="git" data-name="Github"
                                                                  fill="currentColor"
                                viewBox="0 0 512 512"
                                preserveAspectRatio="xMidYMid meet">
                                <path
                                    d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z"></path>
                            </svg>
                        </a>
                    </span>
                    <div class="pl-10 text-gray-700 transition duration-150">
                        <p>FriendsFM is my
                            current side project -- an iOS and Android app for sharing your currently
                            playing song. A notification is sent to all users at a random time each day
                            (like <a target="_blank" href="https://bere.al/en">a bereal</a>), prompting users to share
                            what they're listening to on Spotify or Apple Music. It is available on Android, iOS, and the web.
                        </p>
                        <p>Download it and add <a href="https://friendsfm.app/user/morgan">me here!</a></p>
                        <span
                            class="uppercase text-gray-500 dark:text-gray-400 text-xs font-bold tracking-wider transition duration-150">svelte,
                            typescript, tailwindcss, firebase, node.js, capacitorjs, serverless, ios, android</span>
                    </div>
                </li>
                <li>
                    <a href="https://audial.mogdan.xyz" target="_blank">audial</a>
                        <a target="_blank" href="https://github.com/mdean808/audial" title="view the source on github">
                            <svg
                                class="mb-1 ml-1 inline w-6 h-6 text-white hover:text-black"
                                data-donate="true" data-tag="git" data-name="Github"
                                                                  fill="currentColor"
                                viewBox="0 0 512 512"
                                preserveAspectRatio="xMidYMid meet">
                                <path
                                    d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z"></path>
                            </svg>
                        </a>
                    <div class="pl-10 text-gray-700 transition duration-150">
                        <p>Hopping on the <a href="https://www.nytimes.com/games/wordle/index.html"
                                target="_blank">wordle</a> trend, this is a web app for guessing a daily song from a
                            given spotify playlist.</p>
                        <span
                            class="uppercase text-gray-500 dark:text-gray-400 text-xs font-bold tracking-wider transition duration-150">svelte,
                            typescript, tailwindcss, firebase, node.js</span>
                    </div>
                </li>
                <li>
                    <a href="https://github.com/mdean808/sc-archive" target="_blank">Student Corner</a>
                    <div class="pl-10 text-gray-700 transition duration-150">
                        <p class="italic">Unfortunately, Student Corner is no longer operating.</p>
                        <p>An award winning service dedicated to improving the project-based learning process. Had over
                        6 thousand registered users and over four thousand projects created.</p>
                        <span
                                class="uppercase text-gray-500 dark:text-gray-400 text-xs font-bold tracking-wider transition duration-150">vue.js,
                                node.js, mongodb, devops, design</span>
                    </div>
                </li>
                <li>
                    <a href="https://github.com/mdean808/media-manager" target="_blank">Media Manager</a>
                    <div class="pl-10 text-gray-700 transition duration-150">
                        <p>A cross-platform desktop application for organizing music libraries. Supports recursive
                            organization, duplicate detection, and more.</p>
                        <span
                            class="uppercase text-gray-500 dark:text-gray-400 text-xs font-bold tracking-wider transition duration-150">electron,
                            node.js, materialize.css</span>
                    </div>
                </li>
                <li>
                    <a href="https://repo.mogdan.xyz/web/xyz.mogdan.tweakmanager/" target="_blank">Tweak Manager</a>
                    <div class="pl-10 text-gray-700 transition duration-150">
                        <p>An iOS app built to help users manage <a
                                href="https://en.wikipedia.org/wiki/IOS_jailbreaking" target="_blank"> jailbreak
                                tweaks</a> by enabling and disabling them using system calls..</p>
                        <span
                            class="uppercase text-gray-500 dark:text-gray-400 text-xs font-bold tracking-wider transition duration-150">objective
                            c, logos</span>
                    </div>
                </li>
                <li>
                    <a href="https://github.com/mdean808/tutorialdb" target="_blank">TutorialDB</a>
                    <div class="pl-10 text-gray-700 transition duration-150">
                        <p class="italic">Unfortunately, TutorialDB is no longer operating.</p>
                        <p>A website for submitting and browsing useful tutorials.</p>
                        <span
                            class="uppercase text-gray-500 dark:text-gray-400 text-xs font-bold tracking-wider transition duration-150">MongoDB,
                            materialize.css, heroku, Node.js</span>
                    </div>
                </li>
                <li>
                    <a href="https://github.com/mdean808/braindash-app" target="_blank">Braindash</a>
                    <div class="pl-10 text-gray-700 transition duration-150">
                        <p>An iOS companion app to the board game <a href="https://en.wikipedia.org/wiki/Balderdash"
                                target="_blank">balderdash</a>. Designed to make it easy to keep track of score and game
                            state.</p>
                        <span
                            class="uppercase text-gray-500 dark:text-gray-400 text-xs font-bold tracking-wider transition duration-150">swift,
                            websockets, node.js</span>
                    </div>
                </li>
                <li>
                    <span class="text-sm text-white">(this website was made using vue.js, tailwindcss, and hosted on <a
                            target="" href="https://github.com/mdean808/mogdan.xyz">github</a>)</span>
                </li>
                <li class="mt-2">
                    <span class="text-gray-700 italic dark:text-white">Looking for more? Visit my <a target="_blank"
                            href="https://github.com/mdean808">Github</a>.</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Projects'
});
</script>

<style scoped>

</style>
